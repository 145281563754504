.p-carousel {
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        background-color: $primaryColor;
    }
}

.p-galleria {
    .p-galleria-indicators .p-galleria-indicator.p-highlight button {
        background-color: $primaryColor;
    }
}

.p-carousel {
    .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        background-color: $primaryColor;
    }
}