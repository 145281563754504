/*#region import*/
@import "assets/layout/styles/layout/layout.scss";
@import "assets/layout/styles/theme/theme-light/mytheme/theme.scss";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import "~@openfonts/sarabun_thai/index.css";
/*#endregion import*/

/*#region coreConfig*/
$gutter: 1rem;
$fontFamily: "sarabun";
$scale:13px;
$fontSize: 13px;
$fontWeight: 400;
$fontWeightBold: 600;

:root {
  --font-family:#{$fontFamily};
  --font-size:#{$fontSize};
  --font-weight:#{$fontWeight};
  --font-weight-bold:#{$fontWeightBold};
}

html {
  height: 100%;
  font-size: $scale;
}

body {
  font-family: var(--font-family);
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  --font-weight-bold:#{$fontWeightBold};
}

.swal2-container {
  z-index: 99999 !important;
}

.cdk-overlay-container {
  z-index: 20000;
}

.custom-modal-height {
  max-height: 100%;
}

.custom-modal-content .p-dialog-content {
  background-color: #FAFAFA;
  padding: 30px;
}

.mat-calendar,
.mat-calendar-table,
.mat-calendar-table-header,
.mat-calendar-body,
.mat-calendar-body-label,
.mat-calendar-body-cell-content,
.mat-calendar-period-button,
.mat-calendar-previous-button,
.mat-calendar-next-button,
.mat-calendar-arrow {
  font-family: var(--font-family);
}

/*#endregion coreConfig*/

/*#region customClass*/
.tree-fullwidth .p-treenode-label {
  width: 100%;
}

.p-splitbutton .p-splitbutton-defaultbutton,
.p-splitbutton.p-button-rounded>.p-splitbutton-defaultbutton.p-button,
.p-splitbutton.p-button-outlined>.p-splitbutton-defaultbutton.p-button {
  flex: 1 1 auto !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: 0 none !important;
}

.p-splitbutton-menubutton,
.p-splitbutton.p-button-rounded>.p-splitbutton-menubutton.p-button,
.p-splitbutton.p-button-outlined>.p-splitbutton-menubutton.p-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.p-tieredmenu {
  width: 16rem !important;
}

.p-panel .p-panel-header {
  font-size: 1.25rem;
}

.p-fieldset-legend {
  border: 0 !important;
  background-color: transparent !important;
}

.p-tree {
  .p-tree-container .p-treenode .p-treenode-content.p-highlight {
    background: #ffffff;
    color: #495057;
  }

  .p-tree-container .p-treenode .p-treenode-content.p-highlight:hover {
    background: #e9ecef;
    color: #495057;
  }

  .p-checkbox-disabled.p-checkbox .p-checkbox-box {
    border-color: #ced4da;
    background: #ced4da;
  }

  .p-checkbox-disabled.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #3E1046;
    background: #3E1046;
    opacity: .7;
  }

  // li[aria-level="0"]{
  //   .p-treenode-content {
  //     background-color: var(--purple-100);
  //   }
  // }
  // li[aria-level="1"]{
  //   .p-treenode-content {
  //     background-color: var(--gray-100);
  //   }
  // }
  // li[aria-level="2"]{
  //   .p-treenode-content {
  //     background-color: #ffffff;
  //   }
  // }
}

/*#endregion customClass*/