.layout-breadcrumb {
    ol {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
        gap: 1rem;
        color: var(--text-color-secondary);

        li {
            font-weight: 600;
            white-space: nowrap;
            text-transform: uppercase;
        }
    }
}

.content-breadcrumb {
    margin-bottom: 2rem;
    padding: 0 .5rem;
}