$primaryColor: #3E1046;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -30%) !default;
$primaryTextColor: #ffffff;
$textColorGrey600: #757575;
$textColorGrey900: #212121;
$highlightBg: #E3F2FD;
$highlightTextColor: #495057;
$primaryBgColor: #F8F9FA;
$cardBgColor: #E9ECEF;

@import '../_variables';
@import '../../theme-base/_components';
@import '../_extensions';

.p-element,
.p-component,
.p-datepicker-month {
  font-family: var(--font-family);
}

.layout-sidebar-purple {
  --d-sidebar-bg-color: #{$primaryColor};
  --d-sidebar-bg-color-alt: #{$primaryColor};
  --d-sidebar-border: 0 none;
  --d-app-name-color: #{$primaryTextColor};
  --d-menuitem-text-color: #{$primaryTextColor};
  --d-menuitem-hover-bg: rgba(255, 255, 255, 0.1);
  --d-menuitem-active-bg: #{$primaryColor};
  --d-menuitem-text-active-color: #{$primaryTextColor};
}

.layout-menu ul a.active-route {
  background: #561561;
}

.layout-sidebar .app-name {
  font-size: 1.25rem;
  font-weight: 700;
}

.layout-sidebar .logo-image {
  width: 3.5rem;

  img {
    object-fit: contain;
  }
}

.layout-sidebar .layout-menu .layout-root-menuitem .layout-menuitem-root-text {
  display: none;
}

.p-panel .p-panel-header {
  background-color: #{$primaryBgColor};
  border: none;
  font-size: 1.75rem;
  font-weight: 600;
  color: #{$primaryColor};
}

.p-panel .p-panel-content {
  padding-top: 0.5rem;
  border: none;
  background-color: #{$primaryBgColor};
}

.p-datatable-table {
  border-top: 1px solid #{$cardBgColor};
}

.p-datatable .p-datatable-tbody>tr:nth-child(even) {
  background-color: #{$primaryBgColor};
}

.p-datatable .p-datatable-thead>tr>th,
.p-treetable .p-treetable-thead>tr>th {
  color: #{$textColorGrey600};
  white-space: nowrap;
}

.p-datatable .p-datatable-tbody>tr,
.p-treetable .p-treetable-tbody>tr {
  color: #{$textColorGrey900};
}

.p-datatable .p-datatable-tbody > tr > td{
  white-space: nowrap; 
  text-overflow: ellipsis;
  overflow: hidden;
}

.p-inputtext {
  color: #{$textColorGrey900};  
}

.p-dropdown .p-dropdown-label.p-placeholder{
  color: #{$textColorGrey600};
}

.p-dropdown.p-component.p-inputwrapper.p-disabled.p-inputwrapper-filled{
  background-color: #{$cardBgColor};
}

.p-picklist .p-picklist-buttons{
  padding: 0.5rem;
}

textarea{
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-family: var(--font-family);
  padding: 0.5rem;
  color: #{$textColorGrey900};
  
}

.p-paginator .p-paginator-current {
  color: #{$textColorGrey600};
}

.table-container {
  background-color: #{$primaryBgColor};
}

.per-divider .p-divider.p-divider-horizontal {
  margin: 0.5rem 0;
}

.p-dialog {
  .p-dialog-header {
    .p-dialog-title,
    .p-dialog-header-icons.p-dialog-header-icon {
      color: #{$primaryColor};
      font-size: 1.75rem;
    }
    padding: 1.25rem;
    padding-bottom: 0.5rem;
  }
  .p-dialog-content{
    padding: 1.25rem;
  }
}

.p-tag{
  font-size: 1rem;
  font-weight: 400;
}

.p-inputtext.p-component.p-element.ng-pristine.ng-invalid.ng-touched,
.p-inputwrapper.ng-pristine.ng-invalid.ng-touched .p-dropdown {
  border: 1px solid #FF3D32;
  border-radius: 4px;
}

.label-required:after {
  content: " *";
  color: red;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.55;
}

@media screen and (min-width: 1729px) {

  .landing-wrapper,
  .layout-content {
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .layout-wrapper.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu ul a span {
    white-space: normal;
  }

  .layout-wrapper.layout-drawer .layout-sidebar .sidebar-header .logo {
    justify-content: center;
  }

  .layout-wrapper.layout-drawer .layout-sidebar .sidebar-header .logo .app-name {
    display: none;
  }

  .layout-wrapper.layout-drawer .layout-sidebar .sidebar-header .logo .logo-image {
    margin-right: 0rem;
  }

  .layout-wrapper.layout-drawer .layout-sidebar .sidebar-header .layout-sidebar-anchor {
    border: none;
  }

  .layout-wrapper.layout-drawer .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
    display: none;
  }

  .layout-wrapper.layout-drawer.layout-sidebar-active .layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
    display: none;
  }

  .layout-wrapper.layout-drawer .layout-sidebar .layout-menu ul a .layout-menuitem-icon {
    font-size: 1.25rem;
    width: 1.45rem;
  }
}
