.layout-sidebar-orange {
    --d-sidebar-bg-color:#FF9800;
    --d-sidebar-bg-color-alt:#b36a00;
    --d-sidebar-border:0 none;
    --d-app-name-color:rgba(0,0,0,0.8);
    --d-menu-separator-border: 1px solid rgba(0,0,0,0.1);
    --d-menuitem-root-text-color: rgba(0,0,0,0.6);
    --d-menuitem-text-color: rgba(0,0,0,0.8);
    --d-menuitem-hover-bg: rgba(255,255,255,0.1);
    --d-menuitem-active-bg: rgba(255,255,255,0.1);
    --d-menuitem-text-active-color: rgba(0,0,0,0.9);
    --d-menuitem-focus-shadow: 0 0 0 0.2rem rgba(255,255,255,0.1);
}